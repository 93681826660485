import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RatificationInformationForm from '../forms/RatificationInformationForm';

const RatificationInformationDrawer = ({ open, onClose, masterEntityId }) => (
  <Drawer anchor="right" open={open} onClose={onClose}>
    <Box component={Stack} alignItems="center" spacing={4} px={3} my="auto">
      <Typography
        fontSize={16}
        fontWeight="bold"
        color="primary"
        width="80%"
        textAlign="center"
      >
        Confirma que el deudor no ratifica para notificar al equipo
      </Typography>
      <RatificationInformationForm masterEntityId={masterEntityId} onSubmit={onClose} />
    </Box>
  </Drawer>
);

RatificationInformationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  masterEntityId: PropTypes.string.isRequired,
};

export default RatificationInformationDrawer;
