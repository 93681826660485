import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FingoDialog from './FingoDialog';
import { MasterEntityType } from '../../propTypes';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';
import ContactsFormGrid from '../contacts/ContactsFormGrid';

const ContactsDialog = ({
  open,
  onClose,
  masterEntity,
  contactType,
  columns,
}) => (
  <FingoDialog
    open={open}
    handleClose={onClose}
    title="Lista de contactos"
    PaperProps={{ sx: { borderRadius: 4, maxWidth: 900 } }}
    fullWidth
    dialogActionButton={(
      <Button
        id="close-edit-contacts-dialog"
        color="primary"
        variant="contained"
        onClick={onClose}
      >
        Cerrar
      </Button>
    )}
  >
    <ContactsFormGrid
      contactType={contactType}
      masterEntity={masterEntity}
      checkbox={false}
      columns={columns}
      showAllContactTypes
    />
  </FingoDialog>
);

ContactsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  masterEntity: MasterEntityType.isRequired,
  contactType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
};

ContactsDialog.defaultProps = {
  columns: CONTACT_COLUMNS,
  contactType: 'COMMERCIAL',
};

export default ContactsDialog;
