import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { actionTypeToSpanish } from '../../helpers';
import dayjs from '../../config/dayjs';

const UserActions = ({ actions }) => (
  <>
    {actions.length > 0 ? (
      <Grid container sx={{ px: 1, my: 1 }}>
        <Grid item xs={3}>
          <Typography>Fecha</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>Gestión</Typography>
        </Grid>
      </Grid>
    ) : (
      <Typography variant="h2" sx={{ pl: 1, my: 1 }}>
        No hay gestiones disponibles
      </Typography>
    )}

    {actions.length > 0
      && actions.map((action) => (
        <Grid
          key={action.id}
          container
          sx={{
            py: 1.5,
            backgroundColor: (theme) => theme.palette.gray.A100,
            borderRadius: 2,
            px: 1,
            my: 1,
            alignItems: 'center',
          }}
        >
          <Grid item xs={3}>
            <Stack>
              <Typography>
                {dayjs(action.createdAt).format('DD-MM-YYYY')}
              </Typography>
              <Typography variant="caption" sx={{ fontSize: 'sm', mt: 0.1 }}>
                {dayjs(action.createdAt).format('HH:ss')} hrs
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Typography>{actionTypeToSpanish[action.actionType]}</Typography>
          </Grid>
        </Grid>
      ))}
  </>
);

UserActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
};

UserActions.defaultProps = {
  actions: [],
};

export default UserActions;
