import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useSelectedCompany } from '../../hooks';
import { PORTFOLIO_OPTIONS } from '../../graphql';
import usePortfolioDownload from '../../hooks/usePortfolioDownload';
import { formatYearMonthDay } from '../../helpers';
import FingoDialog from './FingoDialog';
import { FingoDateRangePicker } from '../datePickers';
import DateInput from '../datePickers/DateInput';
import dayjs from '../../config/dayjs';

const DownloadCessionsSummaryDialog = ({
  customCompany,
  open,
  onClose,
  product,
  onlyHistoric,
}) => {
  const [states, setStates] = useState({});
  const [errors, setErrors] = useState({});
  const companyId = customCompany !== null ? customCompany : useSelectedCompany()?.id;

  const { data: portfolioOptionsData, loading } = useQuery(PORTFOLIO_OPTIONS);

  const { loading: mutationLoading, generatePortfolioUrl } = usePortfolioDownload();
  const validateStates = () => {
    const newErrors = {
      documentFormat: !states.documentFormat,
      startDate: !states.startDate,
      endDate: !states.endDate,
    };
    setErrors(newErrors);
    return Object.values(newErrors).some(Boolean);
  };

  const handleChange = (event) => {
    setStates((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!validateStates()) {
      generatePortfolioUrl({
        variables: {
          companyId,
          portfolioType: states.portfolioType || null,
          documentFormat: states.documentFormat,
          startDate: states.startDate && formatYearMonthDay(states.startDate),
          endDate: states.endDate && formatYearMonthDay(states.endDate),
          product,
        },
      });
    }
  };

  const handleRangeChange = ({ range }) => {
    setStates((prev) => ({
      ...prev,
      startDate: dayjs(range.startDate),
      endDate: dayjs(range.endDate),
    }));
  };

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title="Descargar resumen de cartera"
      sx={{ zIndex: 1200 }}
    >
      {loading && <LinearProgress />}
      {!loading && (
        <form id="portfolioDownload" onSubmit={onSubmit} noValidate>
          <Stack width="100%" spacing={2} alignItems="center">
            {!onlyHistoric && (
              <FormControl required fullWidth error={!!errors.portfolioType}>
                <FormLabel>Tipo de documento</FormLabel>
                <Select
                  name="portfolioType"
                  onChange={handleChange}
                  value={states.portfolioType || ''}
                >
                  {portfolioOptionsData?.getPortfolioTypeEnum.map(
                    ({ value, label }) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
            <FormControl required fullWidth error={!!errors.documentFormat}>
              <FormLabel>Formato</FormLabel>
              <Select
                name="documentFormat"
                onChange={handleChange}
                value={states.documentFormat || ''}
              >
                {portfolioOptionsData?.getPortfolioFormatEnum.map(
                  ({ value, label }) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            <FormControl required fullWidth>
              <DateInput
                startDate={states.startDate}
                endDate={states.endDate}
                PickerComponent={FingoDateRangePicker}
                pickerProps={{
                  onChange: handleRangeChange,
                  ranges: [
                    {
                      startDate: states.startDate?.toDate() || dayjs().toDate(),
                      endDate: states.endDate?.toDate() || dayjs().toDate(),
                      key: 'range',
                      color: 'primary',
                    },
                  ],
                  direction: 'horizontal',
                }}
              />
            </FormControl>
            <LoadingButton
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              loading={mutationLoading}
              id="download-portfolio"
            >
              Descargar resumen
            </LoadingButton>
          </Stack>
        </form>
      )}
    </FingoDialog>
  );
};

DownloadCessionsSummaryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  onlyHistoric: PropTypes.bool,
  customCompany: PropTypes.number,
};

DownloadCessionsSummaryDialog.defaultProps = {
  onlyHistoric: false,
  customCompany: null,
};

export default DownloadCessionsSummaryDialog;
